import ActRewardsSummaryDataTransformerBlock from '@activebrands/core-web/libs/storyblok/content/CmsDataTransformerBlock/ActRewardsSummaryDataTransformerBlock';
import { useStyletron } from '@activebrands/core-web/libs/styletron';

const ACT_REWARDS_ENABLED = process.env.REACT_APP_ENABLE_ACT_REWARDS === 'true';

interface ActRewardsSummaryModuleProps {
    isGrid?: boolean;
}

const ActRewardsSummaryModule = ({ isGrid = false, ...rest }: ActRewardsSummaryModuleProps) => {
    const [css] = useStyletron();

    if (!ACT_REWARDS_ENABLED) {
        return null;
    }

    return (
        <section className={css({ marginBottom: isGrid ? 0 : 'var(--margin-bottom-module)' })}>
            <ActRewardsSummaryDataTransformerBlock {...rest} />
        </section>
    );
};

export default ActRewardsSummaryModule;
